import React from "react";
import CategoriesComponent from "../components/categories";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import CoursesComponent from "../components/courses";
import SkillsComponent from "../components/skills";
import VideosComponent from "../components/videos";

const MainCoursesComponent = () => {

    const [activeMenu, setActiveMenu] = React.useState({ id: 1, name: "Courses", iconName: "fa-list-check", active: true });

    const [items, setItems] = React.useState([
        { id: 1, name: "Courses", iconName: "fa-list-check", active: true },
        { id: 2, name: "Categories", iconName: "fa-list", active: false },
        // { id: 3, name: "Videos", iconName: "fa-video", active: false },
        { id: 4, name: "Skills", iconName: "fa-graduation-cap", active: false }
    ]);

    function toggleMenu(menu) {
        let tmp = items.slice();

        for (let x = 0; x < tmp.length; x++) {
            tmp[x].active = false;
            if (tmp[x].id === menu.id) {
                tmp[x].active = true;
            }
        }
        setActiveMenu(menu);
        setItems(tmp);

    }

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">
                    <div className="col-lg-3 col-md-3 col-sm-4 rowBtn mt-2">
                        {
                            items.map((item, index) => (
                                <div key={index}
                                    onClick={() => toggleMenu(item)}
                                    className={(item.active ? "settingsButtonActive" : "settingsButtonInActive") + " flex-column me-2"}>
                                    <i className={"fa-solid " + item.iconName + " fa-2x"} style={{ marginBottom: 10 }}></i>
                                    <span className="font12Blue">{item.name}</span>
                                </div>
                            ))
                        }

                    </div>

                    {activeMenu.id === 2 && (<CategoriesComponent menu={activeMenu} />)}
                    {activeMenu.id === 1 && (<CoursesComponent menu={activeMenu} />)}
                    {activeMenu.id === 3 && (<VideosComponent menu={activeMenu} />)}
                    {activeMenu.id === 4 && (<SkillsComponent menu={activeMenu} />)}

                </section>
            </section>

            <Footer />
        </section>
    )
};

export default MainCoursesComponent;