import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable, Footer, GlobalLoader, Header, SideBar } from "../components/commonEmbeds";
import { getCourses, getVideos } from "../middleware/action-creators/coursesActionCreators";
import { getTransactions } from "../middleware/action-creators/transactionsActionCreators";
import { APICALLLIMIT, formatDate, numberWithCommas } from "../middleware/APIs/commonApis";


const Transactions = () => {

    const { coursesReducer: {
        courses, lastCourseCount, coursesWaitingForResponse, coursesResponseMessage,
        pullMoreCoursesLoader, videos, lastVideoCount, videosWaitingForResponse,
        pullMoreVideosLoader,
    },
        transactionsReducer: { transactions, transactionResponseMessage, lastTransactionsPullCount, transactionWaitingForResponse, pullMoreTransactionsLoader },
        usersReducer: { loggedInUser },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    React.useEffect(() => {

        gettingCourses();
        gettingVideos();
        gettingTransactions();
    }, []);

    function gettingCourses(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, sortByPopularity: "yes" };

        if (appending) {
            params["start"] = courses.length;
        }

        dispatch(getCourses(params, appending));
    };

    function gettingVideos(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, sortBySales: "yes" };

        if (appending) {
            params["start"] = videos.length;
        }

        dispatch(getVideos(params, appending));
    };

    function gettingTransactions(appending = false) {
        let params = { start: 0, count: APICALLLIMIT };

        if (appending) {
            params["start"] = videos.length;
        }

        dispatch(getTransactions(params, appending));
    };

    const CoursesTHeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col text-start">Course Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Purchased count</th>
        </tr>
    </thead>

    const CoursesTBody = <tbody>
        {
            courses && courses.map((item, i) => {
                return (
                    <tr className="cursor" key={i}>
                        <th scope="row">{i + 1}.</th>
                        <td className="col-3 text-start">{item.name.en}</td>
                        <td>{numberWithCommas(item.price)}</td>
                        <td className="">{item.purchases_count}</td>
                    </tr>
                )
            })
        }
    </tbody>

    const VideosTHeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col text-start">Video Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Purchased count</th>
        </tr>
    </thead>

    const VideosTBody = <tbody>
        {
            videos && videos.map((item, i) => {
                return (
                    <tr className="cursor" key={i}>
                        <th scope="row">{i + 1}.</th>
                        <td className="col-3 text-start">{item.name.en}</td>
                        <td>{numberWithCommas(item.price)}</td>
                        <td className="">{item.purchases_count}</td>
                    </tr>
                )
            })
        }
    </tbody>

    const TransactionsTHeader = <thead className="text-black">
        <tr>
            <th scope="col">ID</th>
            <th scope="col">From</th>
            <th scope="col">Amount</th>
            <th scope="col">Transaction type</th>
            <th scope="col">Status</th>
        </tr>
    </thead>

    const TransactionsTBody = <tbody>
        {
            transactions && transactions.map((item, i) => {
                return (
                    <tr className="cursor" key={i}>
                        <th scope="row">
                            {item.ID}
                        </th>
                        <th scope="row" className="text-left">
                            {item.mobile}
                            <div>
                                <small>{formatDate(item.UpdatedAt, true, true)}</small>
                            </div>
                        </th>
                        <td className="col-3">{`${item.currency} ${numberWithCommas(item.amount)}`}</td>
                        <td>{item.transaction_type}</td>
                        <td className={item.status.toUpperCase() === "FAILED" ? "text-danger" : item.status.toUpperCase() === "PROCESSING" ? "text-warning" : "text-success"}>{item.status}</td>
                    </tr>
                )
            })
        }
    </tbody>


    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <div className="row mt-2">
                        <div className="col-sm">
                            <p className="h5">Transactions</p>
                            {
                                transactionWaitingForResponse ? <GlobalLoader /> :
                                    courses.length === 0 ? <span className="h6 text-danger">{transactionResponseMessage}</span> :
                                        <div className="" style={{ paddingTop: 10 }}>
                                            <CustomTable tableHeader={TransactionsTHeader} tableBody={TransactionsTBody} />

                                            {lastTransactionsPullCount === APICALLLIMIT && (
                                                <div className="text-center">
                                                    {pullMoreTransactionsLoader ? <GlobalLoader /> :
                                                        <span onClick={() => gettingTransactions(true)} className="loadMore">Load more</span>}
                                                </div>
                                            )}
                                        </div>
                            }
                        </div>

                        <div className="col-sm">
                            <p className="h5">Top bought courses</p>
                            {
                                coursesWaitingForResponse ? <GlobalLoader /> :
                                    courses.length === 0 ? <span className="h6 text-danger">{coursesResponseMessage}</span> :
                                        <div className="" style={{ paddingTop: 10 }}>
                                            <CustomTable tableHeader={CoursesTHeader} tableBody={CoursesTBody} />

                                            {lastCourseCount === APICALLLIMIT && (
                                                <div className="text-center">
                                                    {pullMoreCoursesLoader ? <GlobalLoader /> :
                                                        <span onClick={() => gettingCourses(true)} className="loadMore">Load more</span>}
                                                </div>
                                            )}
                                        </div>
                            }
                        </div>

                        <div className="col-sm">
                            <p className="h5">Top bought videos</p>
                            {
                                videosWaitingForResponse ? <GlobalLoader /> :
                                    courses.length === 0 ? <span className="h6 text-danger">{coursesResponseMessage}</span> :
                                        <div className="" style={{ paddingTop: 10 }}>
                                            <CustomTable tableHeader={VideosTHeader} tableBody={VideosTBody} />

                                            {lastVideoCount === APICALLLIMIT && (
                                                <div className="text-center">
                                                    {pullMoreVideosLoader ? <GlobalLoader /> :
                                                        <span onClick={() => gettingVideos(true)} className="loadMore">Load more</span>}
                                                </div>
                                            )}
                                        </div>
                            }
                        </div>
                    </div>



                </section>
            </section>

            <Footer />

        </section>
    )
}

export default Transactions;