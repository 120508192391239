import React from "react";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import VideosComponent from "../components/videos";

const VideoPage = () => {

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <VideosComponent />

                </section>
            </section>

            <Footer />

        </section>
    )
}

export default VideoPage;